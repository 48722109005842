(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:filterAccents
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('neo.components')
    .filter('filterAccents', filterAccents);

  function filterAccents() {
    return function (array, expression, comparator, anyPropertyKey) {
      if (!angular.isArray(array)) {
        if (array == null) {
          return array;
        } else {
          throw minErr('filter')('notarray', 'Expected array but received: {0}', array);
        }
      }

      anyPropertyKey = anyPropertyKey || '$';
      var expressionType = getTypeForFilter(expression);
      var predicateFn;
      var matchAgainstAnyProp;

      switch (expressionType) {
        case 'function':
          predicateFn = expression;
          break;
        case 'boolean':
        case 'null':
        case 'number':
        case 'string':
          matchAgainstAnyProp = true;
        // falls through
        case 'object':
          predicateFn = createPredicateFn(expression, comparator, anyPropertyKey, matchAgainstAnyProp);
          break;
        default:
          return array;
      }

      return Array.prototype.filter.call(array, predicateFn);
    };
  }

  // Helper functions for `filterFilter`
  function createPredicateFn(expression, comparator, anyPropertyKey, matchAgainstAnyProp) {
    var shouldMatchPrimitives = angular.isObject(expression) && (anyPropertyKey in expression);
    var predicateFn;

    if (comparator === true) {
      comparator = equals;
    } else if (!angular.isFunction(comparator)) {
      comparator = function (actual, expected) {
        if (angular.isUndefined(actual)) {
          // No substring matching against `undefined`
          return false;
        }
        if ((actual === null) || (expected === null)) {
          // No substring matching against `null`; only match against `null`
          return actual === expected;
        }
        if (angular.isObject(expected) || (angular.isObject(actual) && !hasCustomToString(actual))) {
          // Should not compare primitives against objects, unless they have custom `toString` method
          return false;
        }

        actual = removeAccents(lowercase('' + actual));
        expected = removeAccents(lowercase('' + expected));
        return actual.indexOf(expected) !== -1;
      };
    }

    function removeAccents(source) {
      if (!angular.isString(source)) return surce;
      var accent = [
          /[\300-\306]/g, /[\340-\346]/g, // A, a
          /[\310-\313]/g, /[\350-\353]/g, // E, e
          /[\314-\317]/g, /[\354-\357]/g, // I, i
          /[\322-\330]/g, /[\362-\370]/g, // O, o
          /[\331-\334]/g, /[\371-\374]/g, // U, u
          /[\321]/g, /[\361]/g, // N, n
          /[\307]/g, /[\347]/g, // C, c
        ],
        noAccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

      for (var i = 0; i < accent.length; i++) {
        source = source.replace(accent[i], noAccent[i]);
      }
      return source;
    }

    predicateFn = function (item) {
      if (shouldMatchPrimitives && !isObject(item)) {
        return deepCompare(item, expression[anyPropertyKey], comparator, anyPropertyKey, false);
      }
      return deepCompare(item, expression, comparator, anyPropertyKey, matchAgainstAnyProp);
    };

    return predicateFn;
  }

  function deepCompare(actual, expected, comparator, anyPropertyKey, matchAgainstAnyProp, dontMatchWholeObject) {
    var actualType = getTypeForFilter(actual);
    var expectedType = getTypeForFilter(expected);

    if ((expectedType === 'string') && (expected.charAt(0) === '!')) {
      return !deepCompare(actual, expected.substring(1), comparator, anyPropertyKey, matchAgainstAnyProp);
    } else if (angular.isArray(actual)) {
      // In case `actual` is an array, consider it a match
      // if ANY of it's items matches `expected`
      return actual.some(function (item) {
        return deepCompare(item, expected, comparator, anyPropertyKey, matchAgainstAnyProp);
      });
    }

    switch (actualType) {
      case 'object':
        var key;
        if (matchAgainstAnyProp) {
          for (var key in actual) {
            // Under certain, rare, circumstances, key may not be a string and `charAt` will be undefined
            // See: https://github.com/angular/angular.js/issues/15644
            if (key.charAt && (key.charAt(0) !== '$') &&
              deepCompare(actual[key], expected, comparator, anyPropertyKey, true)) {
              return true;
            }
          }
          return dontMatchWholeObject ? false : deepCompare(actual, expected, comparator, anyPropertyKey, false);
        } else if (expectedType === 'object') {
          for (key in expected) {
            var expectedVal = expected[key];
            if (angular.isFunction(expectedVal) || angular.isUndefined(expectedVal)) {
              continue;
            }

            var matchAnyProperty = key === anyPropertyKey;
            var actualVal = matchAnyProperty ? actual : actual[key];
            if (!deepCompare(actualVal, expectedVal, comparator, anyPropertyKey, matchAnyProperty, matchAnyProperty)) {
              return false;
            }
          }
          return true;
        } else {
          return comparator(actual, expected);
        }
      case 'function':
        return false;
      default:
        return comparator(actual, expected);
    }
  }

  // Used for easily differentiating between `null` and actual `object`
  function getTypeForFilter(val) {
    return (val === null) ? 'null' : typeof val;
  }

  function hasCustomToString(obj) {
    return angular.isFunction(obj.toString) && obj.toString !== toString;
  }

  var lowercase = function (string) {
    return angular.isString(string) ? string.toLowerCase() : string;
  };


}());
